<template>
  <a-modal
    title="编辑表单"
    width="40%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model ref="form" :model="entity" :rules="rules" v-bind="layout">
        <a-form-model-item label="分组名称" prop="Name">
          <a-input v-model="entity.Name" autocomplete="off" />
        </a-form-model-item>
         <a-form-model-item label="成员" prop="UserIdList">
          <UserSelect v-model="entity.UserIdList" placeholder="请选择成员" allowClear mode="multiple"/>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>
  
<script>
import UserSelect from '@/components/SearchSelect/User'
export default {
  components: { UserSelect },
  props: {
    afterSubmit: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      confirmLoading: false,
      entity: {},
      DepartmentIdTreeData: [],
      RoleOptionList: [],
      GroupOptionList: [],
      rules: {
        Name: [{ required: true, message: '必填' }],
      },
    }
  },
  methods: {
    init() {
      this.visible = true
      this.entity = {}
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    openForm(id) {
      this.init()

      if (id) {
        this.$http.post('/Base_Manage/Base_User/GetTheGroupData', { id: id }).then(resJson => {
          this.entity = resJson.Data
        })
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        this.confirmLoading = true
        this.$http
          .post('/Base_Manage/Base_User/SaveUserGroupData', this.entity)
          .then(resJson => {
            this.confirmLoading = false

            if (resJson.Success) {
              this.$message.success('操作成功!')
              this.afterSubmit()
              this.visible = false
            } else {
              this.$message.error(resJson.Msg)
            }
          })
          .catch(() => {
            this.confirmLoading = false
          })
      })
    },
  },
}
</script>
