<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button v-if="hasPerm('UserGrouping.Add')" type="primary" icon="plus" @click="hanldleAdd()">新建</a-button>
      <a-button
        v-if="hasPerm('UserGrouping.Delete')"
        type="primary"
        icon="minus"
        @click="handleDelete(selectedRowKeys)"
        :disabled="!hasSelected()"
        :loading="loading"
      >
        删除
      </a-button>
      <a-button
        type="primary"
        @click="
          () => {
            this.getDataList()
          }
        "
      >
        刷新
      </a-button>
    </div>

    <a-table
      ref="table"
      :columns="columns"
      :rowKey="row => row.Id"
      :dataSource="data"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :bordered="true"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <template v-if="hasPerm('UserGrouping.Edit')">
            <a @click="handleEdit(record.Id)">编辑</a>
            <a-divider type="vertical" />
          </template>
          <a v-if="hasPerm('UserGrouping.Delete')" @click="handleDelete([record.Id])">删除</a>
        </template>
      </span>
    </a-table>

    <edit-form ref="editForm" :afterSubmit="getDataList"></edit-form>
  </a-card>
</template>

<script>
import EditForm from './EditForm'

const columns = [
  { title: '分组名称', dataIndex: 'Name', width: '10%' },
  { title: '分组成员', dataIndex: 'UserNames' },
  { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' }, width: '100px' },
]

export default {
  components: {
    EditForm,
  },
  mounted() {
    this.getDataList()
    this.getGroupList()
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => `总数:${total} 当前:${range[0]}-${range[1]}`,
      },
      filters: {},
      sorter: { field: 'Id', order: 'desc' },
      loading: false,
      columns,
      queryParam: {},
      visible: false,
      selectedRowKeys: [],
      GroupOptionList: [],
    }
  },
  methods: {
    getDataList() {
      this.selectedRowKeys = []

      this.loading = true
      this.$http.post('/Base_Manage/Base_User/GetAllGroupData', {}).then(resJson => {
        this.loading = false
        this.data = resJson.Data
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    hanldleAdd() {
      this.$refs.editForm.openForm()
    },
    handleEdit(id) {
      this.$refs.editForm.openForm(id)
    },
    handleDelete(ids) {
      var thisObj = this
      this.$confirm({
        title: '确认删除吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            thisObj.submitDelete(ids, resolve, reject)
          }).catch(() => console.log('Oops errors!'))
        },
      })
    },
    submitDelete(ids, resolve) {
      this.$http.post('/Base_Manage/Base_User/DeleteGroupData', ids).then(resJson => {
        resolve()

        if (resJson.Success) {
          this.$message.success('操作成功!')

          this.getDataList()
        } else {
          this.$message.error(resJson.Msg)
        }
      })
    },
    getGroupList() {
      this.$http.post('/Base_Manage/Base_User/GetAllSimpleGroupList', {}).then(resJson => {
        if (resJson.Success) {
          this.GroupOptionList = resJson.Data
        }
      })
    },
  },
}
</script>
